.callout {
    display: flex;
    width: 100%;
    padding: 0;
    border-color: #b10d17;

    a {
        color: #58595b;
        transition: color ease-in 0.2s;

        &:hover {
            color: #b10d17;
        }
    }


    i.fas {
        width: 35px;
        color: white;
        background-color: #b10d17;
        padding: 10px;
    }

    >div {
        padding: 10px;
    }
}