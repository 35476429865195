.dialog-background {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
  transition: background-color 1000ms linear;
  z-index: 5000;
}

.dialog {
  background-color: #f0f0f0;
  padding: 20px;
  max-width: 600px;
  margin: 20% auto;
  display: block;
  overflow: auto;
  z-index: 5000;
}
