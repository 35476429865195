@import '../../../../resources/styles/cyc-2020/variables';

.site-search {
  form {
    display: flex;
    flex-flow: row nowrap;

    input[type='search'] {
      flex-shrink: 1;
    }

    button.search-button {
      margin: 0;
      background-color: $color-blue-light;
      margin-bottom: 6px;
      line-height: 1.4;
      > img {
        width: 1em;
        height: 1em;
      }
      box-shadow: 0 4px 0 0 #e3e6e5;
    }
  }
}
