#incident-exists-display {
  display: flex;
  flex-direction: row;
  background-color: #e3e3e3;
  padding: 25px 20px 20px 20px;

  div:first-child {
    margin-right: 20px;
  }

  #incident-exists-display__header {
    font-size: 1.5em;
    font-weight: bold;
  }
}
