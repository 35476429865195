@import './variables';

html,
body {
  height: 100%;
  width: 100%;
  @include standardFonts();
}

body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container-fluid {
  padding: 0px;
}

.mainBody {
  flex-grow: 1;
  background-color: white;

  .container {
    @media screen and (min-width: 1200px) {
      max-width: 960px;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.alert-message {
  margin-top: 1rem;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: $color-grey-very-light;
  padding-left: 1rem;
}

@media print {
  @page {
    size: 297mm 210mm;
  }
  
  * {
    visibility: hidden;
    -webkit-print-color-adjust: exact;
  }

  .areaToPrint,
  .areaToPrint * {
    visibility: visible;
  }

  .areaToPrint {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.flex-container {
  display: flex;
  justify-content: center;

  >* {
    width: 100%;
    max-width: $break-xxl-min;
    flex: 1 0 auto;
    padding: 0 1.5rem;
  }
}