.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background-color: inherit;
  border-radius: 0.25rem;
  padding: 0 0 0 0;
  margin-bottom: 0;

  .breadcrumb__list {
    display: block;
    font-size: 0.875rem;
    margin: 0;
    padding: 0;

    .breadcrumb__list__item {
      display: inline-block;
      margin: 0;

      &:last-child::after {
        display: none;
      }

      &::after {
        content: '>';
        display: inline;
        font-weight: 400;
        padding: 0 0.25rem 0 0.3rem;
      }

      a {
        display: inline-block;
        color: #12326e;

        &:visited {
          color: #12326e;
        }
        &:hover,
        &:focus,
        &:active {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
  span {
    display: inline;
    font-weight: 600;
  }

  @media screen and (min-width: 760px) {
    .breadcrumb__list {
      font-size: 1rem;
    }
  }
}
