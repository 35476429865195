@import '../../../resources/styles/cyc-2020/variables';

$color-recycling: #0060ac;
$color-refuse: #1b252e;
$color-garden: #258829;

.bin-collections-lookup {
  .bin-collections-lookup__address-lookup {
    input[type='text'] {
      width: 30%;
    }
  }

  .bin-collections-lookup__loader {
    margin-top: 1rem;
  }

  .bin-collections-lookup__round-changes-warning {
    margin: 0.2em 0.6em 1.2em 0.6em;
    display: block;

    &.bin-collections-lookup__round-changes-warning--screen {
      @media print {
        display: none;
      }
    }

    &.bin-collections-lookup__round-changes-warning--print {
      font-size: 2em;
      margin: 0.4em;

      @media screen {
        display: none;
      }
    }
  }

  .bin-collections-lookup__services {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    .bin-collections-lookup__services__service {
      background: $color-grey-light;
      margin-top: 1rem;

      .bin-collections-lookup__services__service__header,
      .bin-collections-lookup__services__service__body {
        padding: 1rem;
      }
      .bin-collections-lookup__services__service__header {
        border-bottom: 6px solid $color-refuse;
      }
    }

    .bin-collections-lookup__services__service--recycling .bin-collections-lookup__services__service__header {
      border-bottom-color: $color-recycling;
    }
    .bin-collections-lookup__services__service--garden .bin-collections-lookup__services__service__header {
      border-bottom-color: $color-garden;
    }
  }

  .bin-collections-lookup__calendar {
    @media screen {
      .bin-collections-lookup__calendar__print-button {
        margin-left: 1rem;
      }
    }

    @media print {
      background: white;
      position: absolute;
      left: 0;
      top: 0;
      font-size: xx-small;
      padding: 0;
      margin: 1px;
      * {
        visibility: initial;
      }

      .bin-collections-lookup__calendar__print-button,
      .bin-collections-lookup__calendar__help-links {
        display: none;
      }

      .bin-collections-lookup__calendar__months {
        margin: 0;
      }
    }

    .bin-collections-lookup__calendar__months {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -1rem;

      .bin-collections-lookup__calendar__months__month {
        margin: 1rem;
        width: 12%;
        flex-grow: 1;
        background: $color-grey-mid;

        @media screen and (max-width: $break-lg-max) {
          width: 20%;
        }

        @media screen and (max-width: $break-md-1-max) {
          width: 25%;
        }

        @media screen and (max-width: $break-md-1-max) {
          width: 25%;
        }

        @media screen and (max-width: $break-sm-min) {
          width: 100%;
        }

        h3 {
          white-space: nowrap;
          text-align: center;
          background: white;
          margin-bottom: 0;
          padding-bottom: 0.25rem;
        }

        > div {
          display: flex;
        }
      }

      .bin-collections-lookup__calendar__months__month__collection {
        flex: 1;
        display: flex;
        padding: 0.4rem 0.8rem;
      }

      .bin-collections-lookup__calendar__months__month__collection--refuse {
        background: $color-refuse;
      }

      .bin-collections-lookup__calendar__months__month__collection--recycling {
        background: $color-recycling;
      }

      .bin-collections-lookup__calendar__months__month__collection--garden {
        background: $color-garden;
      }

      .bin-collections-lookup__calendar__months__month__day,
      .bin-collections-lookup__calendar__months__month__round-type {
        color: white;
        flex-grow: 1;
        white-space: nowrap;
        width: 50%;
      }
      .bin-collections-lookup__calendar__months__month__round-type {
        text-align: right;
      }
    }

    .bin-collections-lookup__calendar__key {
      margin: 2em -0.5em;
      display: flex;
      flex-flow: row wrap;

      > div {
        width: 200px;
        padding: 0.4rem 0.8rem;
        color: white;
        display: flex;
        margin: 0.5em;

        span {
          line-height: 1.2em;
          flex-grow: 1;
        }
        img {
          justify-self: flex-end;
          margin-left: 1em;
          width: auto;
          height: 2em;
          align-self: center;
        }
      }

      .bin-collections-lookup__calendar__key__refuse {
        background: $color-refuse;
      }

      .bin-collections-lookup__calendar__key__recycling {
        background: $color-recycling;
      }

      .bin-collections-lookup__calendar__key__garden {
        background: $color-garden;
      }

      &.bin-collections-lookup__calendar__key--screen {
        @media print {
          display: none;
        }
      }

      &.bin-collections-lookup__calendar__key--print {
        margin: 1px;

        @media screen {
          display: none;
        }
      }
    }

    .bin-collections-lookup__calendar__footer {
      background: $color-blue;
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem;
      justify-content: center;
      margin-bottom: 1rem;

      * {
        color: white;
      }

      @media screen and (max-width: $break-md-1-min) {
        flex-wrap: wrap;

        > div img {
          margin-top: 1rem;
        }
      }

      h4 {
        font-size: 1rem;
        margin-right: 1rem;
      }

      .bin-collections-lookup_calendar__footer__icon {
        align-items: center;
        display: flex;

        img {
          height: 32px;
        }
      }

      .bin-collections-lookup_calendar__footer__message {
        margin-right: 1em;
      }
    }
  }
}
