/* Standard colours */

$color-red: #e22a1b;
$color-red-dark: #b90e00;
$color-red-pale: #f9b9b4;
$color-red-pale-light: #ffddda;
$color-blue-light: #0071b9;
$color-blue: #12326e;
$color-blue-grey: #25303b;
$color-blue-grey-light: #37434f;
$color-grey-dark: #4b4a4a;
$color-grey-mid: #afb6bd;
$color-grey-light: #e3e6e5;
$color-purple: #5c2684;
$color-orange-dark: #c45d00;

/* Standard colours */

$color-red-light: $color-red;
$color-blue-dark: $color-blue;
$color-grey: $color-grey-dark;
$color-grey-text: $color-grey-dark;
$color-grey-very-light: $color-grey-light;
$color-grey-pale: $color-grey-light;
/* Specific colours */

$color-anchor: $color-blue;
$color-anchor-visited: $color-blue-light;
$color-anchor-hover: $color-blue-light;
$color-error: $color-red;
$color-header: $color-blue-grey;
$color-sub-header: $color-grey-light;
$color-footer: $color-grey-mid;
$color-input-border: $color-grey-light;
$color-progress-bar-filler: $color-blue-light;
$color-progress-bar-background: $color-grey-light;

/* Media widths */
$break-xsm-min: 400px;
$break-sm-min: 600px;
$break-md-1-max: 759px;
$break-md-1-min: 760px;
$break-md-2-max: 919px;
$break-md-2-min: 920px;
$break-md-3-max: 959px;
$break-md-3-min: 960px;
$break-lg-max: 1019px;
$break-lg-min: 1020px;
$break-xl-max: 1079px;
$break-xl-min: 1080px;
$break-xxl-max: 1279px;
$break-xxl-min: 1280px;
$break-xxxl-max: 1339px;
$break-xxxl-min: 1440px;

/* Transition speeds */
$transition-speed-fast: 0.2s;
$transition-speed-fast-ish: 0.3s;

@mixin focus-outline() {
  outline: solid 3px $color-orange-dark;
}

/* Fonts */
@mixin standardFonts {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: $color-grey;
}

/* Font sizes */

$font-size-h1: 2.25rem;
$font-size-h2: 1.4rem;
$font-size-h3: 1.2rem;
$font-size-regular: 1.25rem;
$font-size-small: 0.9rem;
$size-small: 0.75rem;
$size-medium: 10px;

/* Media widths */
$to-mobile-view: 600px;
