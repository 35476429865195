@import './variables';

form {
  > p,
  > label,
  > h1,
  > h2,
  > h3 {
    &:first-of-type {
      margin-top: 0 !important;
    }
  }
  label {
    &.input-label-cyc {
      margin-top: 2rem;
    }

    &.form-check-label {
      margin-bottom: 1.125rem;
    }
  }

  button.button-cyc {
    margin-top: 2.25rem;
  }
}
