// Style patches that override esri component css
// these may need changing if we update our version of esri maps

input#esriSearch_input {
  width: auto;
}

// override default esri map popup size
// adjustment made to display unadopted road popup properly
.esri-popup__main-container {
  max-height: 290px !important;
}
