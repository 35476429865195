@import '../../../resources/styles/cyc-2020/variables';
/* Standard colours */

$color-red: #e22a1b;
$color-red-dark: #b90e00;
$color-blue-light: #0071b9;
$color-blue: #12326e;
$color-blue-grey: #25303b;
$color-blue-grey-light: #37434f;
$color-grey-dark: #4b4a4a; //#232121;
$color-grey-mid: #afb6bd;
$color-grey-light: #e3e6e5; //#444;
$color-purple: #5c2684;
$color-orange-dark: #c45d00;

@mixin selectionOutline {
  // need to use important to override button:focus set elsewhere
  // check if needed once restyling complete
  outline: transparent solid 3px !important;

  &:active,
  &:focus {
    outline-color: $color-orange-dark !important;
  }
}

.myaccount-header {
  background-color: $color-blue-grey;
  display: flex;
  min-width: 100%;
  z-index: 995;

  @media screen and (min-width: $break-xxxl-min) {
    justify-content: center;
  }

  // clear button styles
  button {
    background-color: transparent;
    border: none;
    color: inherit;
  }

  input,
  button,
  .menu-item {
    line-height: 1.6;
    @include selectionOutline();
  }

  .myaccount-header__container--black-hover-effect {
    border: 1px solid transparent;
    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &:hover,
    &:active,
    &:focus {
      background: black;
      border: 1px solid white;
    }
  }

  .myaccount-header__container {
    max-width: 1440px;
    display: flex;
    width: 100%;
  }

  @media screen and (min-width: $break-sm-min) {
    padding: 0.75rem 1.5rem;
  }

  @media screen and (min-width: $break-md-2-min) {
    padding: 0.75rem 1.5rem;
  }

  @media screen and (min-width: $break-md-3-min) {
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  }

  .myaccount-myaccount-header--background-color {
    // For IE11 support. Inherit does not give this color
    background-color: $color-blue-grey;
  }

  .myaccount-header__container__cyc-logo-container {
    padding: 0.5rem;
    cursor: pointer;

    @include selectionOutline();

    @media screen and (min-width: $break-md-3-min) {
      padding: 1.5rem;
    }

    img {
      width: 100%;
      min-width: 108px;
    }
  }

  ul.myaccount-header__navigation-links {
    color: white;
    font-size: 1.125rem;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;

    li.search-list-item {
      // Hide search on mobile devices
      @media screen and (max-width: $break-xsm-min) {
        display: none;
      }
      .search-icon {
        position: relative;

        @media screen and (min-width: $break-md-1-min) {
          padding-right: 3.75rem;
          padding-left: 1.25rem;
        }

        .icon-ui-search {
          // magnification icon
          background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2260%22%20height%3D%2260%22%20viewBox%3D%220%200%2060%2060%22%20preserveAspectRatio%3D%22xMinYMid%22%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%3E%3Cpath%20d%3D%22M57.52%2C55.19%2C44.77%2C42.44a24.49%2C24.49%2C0%2C1%2C0-2.33%2C2.33L55.19%2C57.52a1.65%2C1.65%2C0%2C0%2C0%2C2.33-2.33ZM26.41%2C47.52A21.11%2C21.11%2C0%2C1%2C1%2C47.52%2C26.41%2C21.14%2C21.14%2C0%2C0%2C1%2C26.41%2C47.52Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          transition: opacity 0.3s ease;
          display: block;
          position: absolute;
          top: calc(50% - 1.6875rem / 2);
          right: calc(50% - 1.6875rem / 2);

          @media screen and (min-width: $break-md-1-min) {
            right: 1.25rem;
          }

          height: 1.6875rem;
          width: 1.6875rem;

          &:not(open) {
            opacity: 1;
          }

          &.open {
            opacity: 0;
          }
        }

        .icon-closed-ui-search {
          // closed search icon - a cross
          display: block;
          position: absolute;
          top: calc(50% - 1.6875rem / 2);
          right: calc(50% - 1.6875rem / 2);
          margin-left: -2px;
          margin-top: 1px;

          @media screen and (min-width: $break-md-1-min) {
            right: 1.25rem;
          }

          height: 1.6875rem;
          width: 1.6875rem;

          &::before,
          &::after {
            background-color: #fff;
            transition: all 0.3s ease;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            top: calc(50% - 2px);
            width: 100%;
          }

          &:not(open)::before {
            -webkit-transform: rotate(45deg) scaleX(0.5) translateY(0);
            -ms-transform: rotate(45deg) scaleX(0.5) translateY(0);
            transform: rotate(45deg) scaleX(0.5) translateY(0);
            opacity: 0;
          }

          &:not(open)::after {
            -webkit-transform: rotate(-45deg) scaleX(0.5) translateY(0);
            -ms-transform: rotate(-45deg) scaleX(0.5) translateY(0);
            transform: rotate(-45deg) scaleX(0.5) translateY(0);
            opacity: 0;
          }

          &.open::before {
            -webkit-transform: rotate(-45deg) scaleX(1) translateY(0);
            -ms-transform: rotate(-45deg) scaleX(1) translateY(0);
            transform: rotate(-45deg) scaleX(1) translateY(0);
            opacity: 1;
          }

          &.open::after {
            -webkit-transform: rotate(45deg) scaleX(1) translateY(0);
            -ms-transform: rotate(45deg) scaleX(1) translateY(0);
            transform: rotate(45deg) scaleX(1) translateY(0);
            opacity: 1;
          }
        }
      }
    }

    .menu-item {
      display: flex;
      align-items: center;
      margin: 5px;
      line-height: 1.1;
      letter-spacing: 0.05em;
      height: 3.125rem;
      width: 3.125rem;
      cursor: pointer;

      @media screen and (min-width: $break-md-1-min) {
        width: auto;
        padding: 0 1.5rem 0 1.25rem;
      }

      @media screen and (min-width: $break-md-2-min) {
        margin: 5px 0.75rem;
      }

      &.button-cyc-primary {
        font-size: 1rem;

        @media screen and (max-width: $break-xl-min) {
          border-color: transparent;
          background-color: transparent;
          padding: 0;

          &:hover,
          &:active,
          &:focus {
            background: black;
            border: 1px solid white;
          }
        }

        .toggle {
          display: inline-block;
          height: 1.5rem;
          margin-left: 0.7rem;
          margin-top: -2px;
          position: relative;
          vertical-align: middle;
          width: 1.5rem;
        }

        .toggle::before,
        .toggle-icon__line,
        .toggle::after {
          background-color: #fff;
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          -webkit-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
          transform-origin: 0 0;
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s;
          width: 100%;

          &:focus,
          &:active {
            background-color: $color-red;
          }
        }

        .toggle::before {
          content: '';
          -webkit-transform: rotate(0deg) scaleX(1) translateY(-0.5625rem);
          -ms-transform: rotate(0deg) scaleX(1) translateY(-0.5625rem);
          transform: rotate(0deg) scaleX(1) translateY(-0.5625rem);
        }

        .toggle::after {
          content: '';
          -webkit-transform: rotate(0deg) scaleX(1) translateY(0.5625rem);
          -ms-transform: rotate(0deg) scaleX(1) translateY(0.5625rem);
          transform: rotate(0deg) scaleX(1) translateY(0.5625rem);
          -webkit-transform-origin: 0 100%;
          -ms-transform-origin: 0 100%;
          transform-origin: 0 100%;
        }

        &:hover {
          .toggle::before {
            -webkit-transform: rotate(-45deg) scaleX(0.5) scaleY(1) translateY(0);
            -ms-transform: rotate(-45deg) scaleX(0.5) scaleY(1) translateY(0);
            transform: rotate(-45deg) scaleX(0.5) scaleY(1) translateY(0);

            @media screen and (min-width: $break-md-1-min) {
              background-color: $color-red;
            }
          }

          .toggle::after {
            -webkit-transform: rotate(45deg) scaleX(0.5) scaleY(1) translateY(0);
            -ms-transform: rotate(45deg) scaleX(0.5) scaleY(1) translateY(0);
            transform: rotate(45deg) scaleX(0.5) scaleY(1) translateY(0);

            @media screen and (min-width: $break-md-1-min) {
              background-color: $color-red;
            }
          }

          .toggle-icon__line {
            -webkit-transform: scaleX(1) scaleY(1);
            -ms-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
            -webkit-transform-origin: 25% 50%;
            -ms-transform-origin: 25% 50%;
            transform-origin: 25% 50%;

            @media screen and (min-width: $break-md-1-min) {
              background-color: $color-red;
            }
          }
        }
      }

      &.council-services {
        &.screen-small {
          display: none;
        }
        &.screen-large {
          display: unset;
        }
        @media screen and (max-width: $break-xl-min) {
          &.screen-small {
            display: block;
          }
          &.screen-large {
            display: none;
          }
        }
      }
    }

    a,
    a:hover,
    a:visited {
      color: white;
      text-decoration: none !important;
      font-weight: 400;
    }

    .account-navigation {
      display: none;

      @media screen and (min-width: $break-xl-min) {
        display: flex;
      }
    }

    .browsealoud-placeholder {
      width: 3.125rem;
      margin-left: 0.25rem;

      @media screen and (min-width: $break-md-2-min) {
        margin-left: 1.25rem;
      }
    }
  }

  .myaccount-header__link-text {
    display: none;

    @media screen and (min-width: $break-md-1-min) {
      display: inline-block;
    }
  }
}

#__ba_panel.topRight._launchpad {
  height: 3rem !important;
  width: 3rem !important;
  top: 1rem !important;
  left: calc(100vw - 5rem) !important;
  z-index: 100 !important;

  &:before,
  &:after {
    border: 2px solid #ed8506;
    border-radius: 50%;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transform: scale(0);
    width: 100%;
  }

  &:hover {
    &:before {
      animation: pulse 2s ease 0s infinite;
    }

    &:after {
      animation: pulse 2s ease 1s infinite;
    }
  }

  @media screen and (min-width: $break-xsm-min) {
    top: 2.5rem !important;
  }

  @media screen and (min-width: $break-md-1-min) {
    left: calc(100vw - 5.5rem) !important;
  }

  @media screen and (min-width: $break-md-2-min) {
    top: 3.125rem !important;
  }

  @media screen and (min-width: $break-md-3-min) {
    top: 3.5rem !important;
    // left: calc(100vw - 5rem) !important;
  }

  #_ba_corner {
    height: 3rem !important;
    width: 3rem !important;

    img {
      transform: scale(0.7) !important;
      top: 4px !important;
      left: 0 !important;
    }
  }
}
