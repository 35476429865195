@import '../../../../resources/styles/cyc-2020/variables';
@import '../../../../resources/styles/cyc-2020/text';

.myaccount-flyout {
  z-index: 999;
  position: fixed;
  background-color: $color-blue;
  padding: 0.625rem 1.625rem 0 0.625rem;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  @media screen and (min-width: $break-md-1-min) {
    width: 33%;
    min-width: 300px;
  }

  &.myaccount-flyout--closed {
    right: -100vw;
    width: 100vw;
    opacity: 0;
    transition-property: right, opacity;
    @media screen and (min-width: $break-md-1-min) {
      width: 33%;
    }

    .myaccount-flyout__canvas {
      display: none;
    }
  }

  .myaccount-flyout__controls {
    z-index: 999;
    .myaccount-flyout__close {
      text-transform: uppercase;
      background-color: transparent;
      color: white;
      border: 1px solid transparent;
      transition-property: background-color, border-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;
      font-size: 1.5rem;
      float: right;
      right: 0;
      top: 0;

      &:hover,
      &:active,
      &:focus {
        background: black;
        border: 1px solid white;
      }

      .toggle-icon {
        display: inline-block;
        height: 1.5rem;
        margin-left: 0.5rem;
        margin-top: -5px;
        position: relative;
        vertical-align: middle;
        width: 1.5rem;
        &::before,
        &::after {
          background-color: #fff;
          content: '';
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          width: 100%;
        }
        &::before {
          -webkit-transform: rotate(-45deg) scaleX(1) translateY(0);
          -ms-transform: rotate(-45deg) scaleX(1) translateY(0);
          transform: rotate(-45deg) scaleX(1) translateY(0);
        }

        &::after {
          -webkit-transform: rotate(45deg) scaleX(1) translateY(0);
          -ms-transform: rotate(45deg) scaleX(1) translateY(0);
          transform: rotate(45deg) scaleX(1) translateY(0);
        }
      }
    }
  }

  .myaccount-flyout__canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px black;
    background: black;
    opacity: 0.3;
    z-index: 998;
  }

  .myaccount-flyout__content {
    z-index: 999;
    *:not(input) {
      color: white;
    }
    ul {
      list-style: none;
      padding: 0;

      li {
        border-bottom: 1px solid $color-grey-dark;
        padding: 0.75rem;
        > * {
          color: white !important;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
