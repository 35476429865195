@import './variables';

@mixin link-style {
  color: $color-blue;
  text-decoration: underline;
  font-size: inherit;
  transition: color $transition-speed-fast-ish;

  &:hover {
    text-decoration: underline;
    color: $color-blue-light;
  }

  &:focus {
    outline: solid 3px $color-orange-dark;
  }

  &:visited {
    color: $color-anchor-visited;
  }
}

/* text elements */
h1,
h2,
h3,
p,
b,
i,
label,
small,
li,
td,
span,
button,
a {
  &::selection {
    background: #ffc733;
  }
}

h1,
h2,
h3 {
  color: $color-blue-grey;
}

h1,
h2 {
  font-weight: 600;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

p,
input,
textarea,
label,
select,
ul,
ol,
dl {
  font-size: $font-size-regular;
  color: $color-grey-dark;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  &::selection {
    background: #ffc733;
  }
}

p {
  margin-top: 2rem;

  &.cyc-error-message {
    margin: 0;
  }
}

small {
  font-size: $font-size-small;
}

a {
  @include link-style();
}

.sentence-case {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

table.sentence-case {
  th,
  td {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

button.in-text-button {
  display: inline;
  border: none;
  background: none;
  padding: 0;

  @include link-style();
}

.uppercase {
  text-transform: uppercase;
}

.word-break {
  word-break: break-word;
}
